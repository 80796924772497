<template>
  <div class="card card-icon mb-4">
    <div class="row no-gutters">
      <!-- Icon -->
      <div
        class="d-none
            d-md-flex
            col-auto
            card-icon-aside
            bg-primary
            text-white"
      >
        <i data-feather="folder"></i>
      </div>

      <div class="col">
        <div class="card-body py-4">
          <h5 class="card-title">
            <span :class="isEditing ? 'text-danger' : ''">{{ showFormTitle }}</span>
          </h5>

          <div class="card-text">
            <FormulateForm ref="administrativeUnitForm" @submit="submit" #default="{ hasErrors, isLoading }">
              <div class="form-group">
                <b-row>
                  <b-col cols="12" md="6">
                    <FormulateInput
                      v-model="formValues.nombre"
                      label="Nombre"
                      placeholder="Ingresa el nombre de la unidad administrativa"
                      :disabled="isLoading"
                      :input-class="(ctx, classes) => getInputClasses(ctx, classes)"
                      validation="bail|required"
                      :validation-messages="{
                        required: 'El nombre de la unidad administrativa es requerido'
                      }"
                    />
                  </b-col>
                  <b-col cols="12" md="6">
                    <FormulateInput
                      v-model="formValues.clave"
                      label="Clave"
                      placeholder="Ingresa la clave de la unidad administrativa"
                      :disabled="isLoading"
                      :input-class="(ctx, classes) => getInputClasses(ctx, classes)"
                      validation="bail|required"
                      :validation-messages="{
                        required: 'La clave de la unidad administrativa es requerida'
                      }"
                    />
                  </b-col>
                </b-row>
              </div>

              <div class="form-group">
                <b-row>
                  <b-col cols="12" md="6">
                    <label
                      class="formulate-input-label formulate-input-label--before small font-weight-bolder"
                    >
                      Periodo fiscal
                    </label>
                    <check-authorization :requiresAuthorizations="['listar periodos fiscales']">
                      <x-select
                        v-model="formValues.periodo_fiscal_id"
                        dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                        getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                        :formatListWith="['id', 'nombre']"
                      >
                      </x-select>
                    </check-authorization>
                  </b-col>

                  <b-col cols="12" md="6">
                    <label
                      class="formulate-input-label formulate-input-label--before small font-weight-bolder"
                    >
                      Dependencia a otra unidad administrativa
                    </label>
                    <check-authorization :requiresAuthorizations="['listar unidades administrativas']">
                      <x-select
                        v-model="formValues.unidad_administrativa_id"
                        dispatchPath="administrativeUnitModule/getAdministrativeUnits"
                        getterListPath="administrativeUnitModule/getAdministrativeUnits"
                        :formatListWith="['id', 'nombre']"
                      >
                      </x-select>
                    </check-authorization>
                  </b-col>
                </b-row>
              </div>

              <!-- Boton para enviar o actualizar -->
              <div class="mt-4 d-flex flex-row-reverse">
                <div class="w-100 d-flex justify-content-between">
                  <button
                    :disabled="isLoading"
                    @click="resetForm()"
                    type="reset"
                    class="btn btn-black"
                  >
                    Cancelar
                  </button>

                  <button v-if="isEditing" type="submit" :disabled="hasErrors || isLoading" class="btn btn-warning">
                    {{ isLoading ? 'Actualizando...' : 'Actualizar' }}
                  </button>

                  <button
                    v-else
                    type="submit"
                    :disabled="hasErrors || isLoading"
                    class="btn btn-success"
                  >
                    {{ isLoading ? 'Creando unidad administrativa...' : 'Crear unidad administrativa' }}
                  </button>
                </div>
              </div>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const formValues = () => ({
  nombre: '',
  clave: '',
  unidad_administrativa_id: '',
  periodo_fiscal_id: ''
})

export default {
  name: 'AdministrativeUnitForm',

  data () {
    return {
      formValues: formValues(),

      isEditing: false
    }
  },

  computed: {
    ...mapState('administrativeUnitModule', ['editingAdministrativeUnit']),

    ...mapGetters('fiscalPeriodsModule', ['getFiscalPeriodsForForms']),

    ...mapGetters('administrativeUnitModule', ['getAdministrativeUnitsForForms', 'hasAdministrativeUnits']),

    showFormTitle () {
      return this.isEditing ? 'Editando: ' + this.editingAdministrativeUnit.nombre : 'Crea una nueva unidad administrativa'
    }
  },

  watch: {
    editingAdministrativeUnit: {
      deep: true,
      inmediate: true,
      handler (editingAdministrativeUnit) {
        this.isEditing = Boolean(editingAdministrativeUnit)

        if (this.isEditing) {
          this.formValues = editingAdministrativeUnit
          return
        }

        this.resetForm()
      }
    },

    reset: {
      handler (reset) {
        if (reset) {
          this.resetForm()
        }
      }
    }
  },

  methods: {
    /**
     * Envía el formulario
     */
    async submit () {
      const getval = this.isEditing
        ? await this.updateAdministrativeUnit(this.formValues)
        : await this.createAdministrativeUnit(this.formValues)

      this.$notify(getval, 'Envío de formulario')

      if (!getval.error) this.resetForm()
    },

    async createAdministrativeUnit (form) {
      return await this.$store.dispatch('administrativeUnitModule/createAdministrativeUnit', form)
    },

    async updateAdministrativeUnit (form) {
      return await this.$store.dispatch('administrativeUnitModule/updateAdministrativeUnit', {
        id: form.id,
        payload: form
      })
    },

    getInputClasses (context, classes) {
      const c = context.hasErrors ? ['border-danger'] : ['border-success']
      return classes.concat(c)
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit('administrativeUnitModule/setAdministrativeUnitToEdit', null)
      this.formValues = formValues()
      if (this.$refs.administrativeUnitForm) {
        this.$refs.administrativeUnitForm.hideErrors()
        this.$emit('hideForm')
      }
      window.scrollTo(0, 0)
    }
  }
}
</script>
